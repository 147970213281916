<h1>Projects</h1>
<div class="container">
<div class="description">
<p>My first great project was about a dashboard to manage and generate tests where I was studying in 2017, since then I have been developing web pages and web applications.</p>
</div>
<div class="description">
<p>I coded a web page where the kids could to learn mathematic operations like add, multiplication etc.</p>
</div>
<div class="description">
<p>I was working on the new web page to get job, the client was a government office called Secretaria del Trabajo y Previsión Social, you can see it here.</p>
</div>
<div class="description">
<p>I was working on a web page to manage some activities the employees did daily, the client was another goverment office called Secretaria de Contraloria del Estado de México. You can see it here.</p>
</div>
<div class="description">
<p>More recently I am working on some projects coded with java the clients are ClaroShop, Sears and Sanborns.</p>
</div>
</div>
