<h1>Skills</h1>
<div class="container">
<div class="description">
<p>From beginning since I started my carrer I have coded a variety of programming languages such as:</p>
<ul>
    <li>Javascript (advanced)</li>
    <li>PHP (intermediate)</li>
    <li>PL/SQL (beginner)</li>
    <li>Python (intermediate)</li>
    <li>Typescript (advanced)</li>
    <li>Java (advanced)</li>
</ul>
</div>
<div class="description">
<p>Also I have participated at projects about big data:</p>
<ul>
    <li>hadoop big data (beginner)</li>
</ul>
</div>
<div class="description">
<p>I have knowledge on operating systems too such as:</p>
<ul>
    <li>Windows (intermediate)</li>
    <li>Linux - Centos (intermediate)</li>
</ul>
</div>
<div class="description">
<p>I am learning deeply english language and now I have a B2 level</p>
</div>
<div class="description">
<p>Since I been coding around the projects I have been working with some frameworks such as:</p>
<ul>
    <li>Laravel</li>
    <li>Angular</li>
    <li>React</li>
    <li>Spring</li>
</ul>
</div>
</div>


