<h1>Files</h1>

<p>I kindly share with you some formats that I think they could be helpful to support my experience and skills.</p>

<div class="container">
    <div id="cv" class="description">
        <a href="../../assets/documentos/cesars-resume.pdf" download="cv_cessar.pdf">Resume</a>
    </div>
    <div id="springCertificate" class="description">
        <a href="../../assets/documentos/diplomaSpringCourse.pdf" download="spring_certificate.pdf">Spring Certificate</a>
    </div>
    <div id="englishCertificate" class="description">
        <a href="../../assets/documentos/B2Certificate.pdf" download="english_certificate.pdf">English Certificate</a>
    </div>

</div>
