import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren("categories") private categories: QueryList<ElementRef<HTMLElement>>;
  categoriesButtonClicked: Subscription = new Subscription();
  @ViewChild('categoriesButton') categoriesButton: ElementRef;
  @ViewChild('categoriesContainer') categoriesContainer: ElementRef;
  isShowing:boolean = false;

  constructor() { }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void{
    this.getWhenCategoriesButtonIsClicked();
    this.setColorAboutCategory();
  }

  public changingColorSelectedCategori(event: any):void {
    let linksArr = this.gettingLinksArr();
    for(let i=0; i<linksArr.length; i++){
      linksArr[i].firstChild.style.backgroundColor = "";
    }
    event.target.style.backgroundColor = "#959ec2";
    this.x();
  }

  private setColorAboutCategory(): void{
    let linksArr = this.gettingLinksArr();
      linksArr[0].firstChild.style.backgroundColor = "#959ec2";
  }

  private gettingLinksArr(): Array<any>{
    let arr = this.categories.toArray();
    let ulTag = arr[0].nativeElement;
    let linksArr: Array<any> = Array.from(ulTag.children);
    return linksArr;
  }

  private getWhenCategoriesButtonIsClicked(): void{
    this.categoriesButtonClicked = fromEvent(this.categoriesButton.nativeElement, "click")
    .subscribe(()=>{
      this.x();
    });
  }

  private x():void{
    if(!this.isShowing){ 
      this.categoriesContainer.nativeElement.classList.remove("divCategories");
      this.isShowing = true;
    }else{
      this.categoriesContainer.nativeElement.classList.add("divCategories");
      this.isShowing = false;
    }
  }

  ngOnDestroy(){
    //For performance reasons
    this.categoriesButtonClicked.unsubscribe();
  }

}
