<nav class="navbar navbar-dark navColor" id="navegationBar">
    <div #categoriesButton id="categoriesButton">
      <img id="zorktechImg" src="../../assets/imagenes/zorktech.png" />
    </div>
</nav>
<div #categoriesContainer class="divCategories">
    <div class="ul" #categories (click)="changingColorSelectedCategori($event)">
        <a routerLink="/about"><li>About Me</li></a>
        <a routerLink="/skills"><li>Skills</li></a>
        <a routerLink="/projects"><li>Projects</li></a>
        <a routerLink="/interests"><li>Interests</li></a>
        <a routerLink="/files"><li>Files</li></a>
        <a routerLink="/contact"><li>Contact</li></a>
    </div>
</div>