<h1>César Alberto Soto Reyes</h1>
<div class="container">
    <div class="containerDiv description">
        <p>Hi I'm Cesar. I have an Information Technologies degree by the Autonomous Mexico's State University but I am specialized in software development since many years ago.</p>
    </div>
    <div id="image-container" class="containerDiv">
        <img src="../../assets/imagenes/yop.jpg" />
    </div>
</div>

