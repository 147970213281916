import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutsComponent } from './layouts/layouts.component';
import { AboutComponent } from './about/about.component';
import { SkillsComponent } from './skills/skills.component';
import { ProjectsComponent } from './projects/projects.component';
import { InterestsComponent } from './interests/interests.component';
import { FilesComponent } from './files/files.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  {
    path: '', component: LayoutsComponent,
    children: [
      { path: 'about', component: AboutComponent }
      ,{ path: "skills", component: SkillsComponent }
      ,{ path: "projects", component: ProjectsComponent }
      ,{ path: "interests", component: InterestsComponent }
      ,{ path: "files", component: FilesComponent }
      ,{ path: "contact", component: ContactComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
